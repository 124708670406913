<template>
<div>
           <v-row no-gutters> 
            <v-col cols="12" sm="12" class="pa-4"> 
            <v-simple-table dense  style="width: 100%">
            <template v-slot:default>
              <tbody>
              <template>
                <tr>
                  <th>Nombre</th>
                  <td class="text-left">{{info.nombre}} {{info.apellido}}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td class="text-left">{{info.correo}}</td>
                </tr>
                <tr>
                  <th>Teléfono</th>
                  <td class="text-left">{{info.celular}} {{info.telefono}}</td>
                </tr>
                <tr>
                  <th>Dirección</th>
                  <td class="text-left">{{info.dir}}</td>
                </tr>
               </template>
              </tbody>
            </template>
          </v-simple-table>
            </v-col>
           </v-row>
        </div>
</template>

<script>

  export default {
    name: 'NgInfoCliente',
    props: ['info'],
  }
</script>

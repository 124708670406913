<template>
  <div style="background-color: white; height: 100vh">
    <v-app-bar app color="primary" dense  dark style="left: 0px !important">
      <div class="text-h3 text-center">{{ item.cliente }}</div>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>

      <!--template v-slot:actions>
              <router-link  to="/admin/solicitudes/list-desembolsado"  class="mr-2 white-text" >
                Volver
              </router-link>
          
         </template-->
    </v-app-bar>
    <div v-if="item">
      <br /><br /><br />
      <div class="text-center">
        <!--div class="text-h3 text-center">
              {{item.cliente}}
            </div--><br />
        <div class="text-h4 text-center">
          Total a cobrar: {{ $filters.currency(item.valor_vencido) }}
        </div>
      </div>
      <v-row no-gutters class="mt-2">
        <v-col cols="12" class="pa-4">
          <v-simple-table dense style="width: 100%">
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Monto crédito</th>
                  <td>{{ $filters.currency(item.monto) }}</td>
                </tr>
                <tr>
                  <th>Plazo</th>
                  <td>{{ item.plazo }}</td>
                </tr>
                <tr>
                  <th>Días Vencidos</th>
                  <td>{{ item.dias_sin_pagar }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="12" class="mt-2">
          <div class="text-center"><b>Cliente</b></div>
          <ng-info-cliente :info="item.cliente_info" />
        </v-col>
        <v-col cols="12" md="12" class="mt-2" v-if="item.codeudor_info">
          <div class="text-center"><b>Codeudor</b></div>
          <ng-info-cliente :info="item.codeudor_info" />
        </v-col>
      </v-row>
      <div class="mx-2">
        <v-expansion-panels
          class="mb-6"
          v-model="panel"
          multiple
          accordion
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header color="primary">
              <div class="text-subtitle-2 white-text">
                Cuotas Generadas
              </div></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <cuotas-credito-simple
                :solicitud="item.id"
              ></cuotas-credito-simple>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="pb-6">
            <v-expansion-panel-header color="primary">
              <div class="text-subtitle-2 white-text">
                Historial de Gestiones
              </div></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <historial-seguimiento
                :solicitud="item.id"
              ></historial-seguimiento>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { NgForm, handler } from "@/components/gui/NgForm";
import NgInfoCliente from "./NgInfoCliente";
import CuotasCreditoSimple from "./CuotasCreditoSimple";
import HistorialSeguimiento from "./HistorialSeguimiento";

export default {
  name: "HistorialCredito",
  components: { NgInfoCliente, CuotasCreditoSimple, HistorialSeguimiento },
  data() {
    return {
      panel: [],
      user: null,
      user: null,
      dialogEmail: false,
      dialogTask: false,
      dialogComment: false,
      dialogLawyer: false,
      loading: false,
      // item: null
    };
  },
  props: ["item", "event", "modal", "task"],
  created() {
    let u = localStorage.getItem("user");
    this.user = JSON.parse(u);
    //console.log(this.$store.user)
  },
  methods: {
    sendSerprogreso() {
      if (confirm("Esta seguro que desea devolver la gestión a Serprogreso?")) {
        this.loading = true;
        const value = { solicitud_id: this.item.id };
        this.$http
          .post("api/admin/seguimiento-send-serprogreso", { data: value })
          .then((res) => {
            this.loading = false;
            if (res.data.success) {
              //this.snackbar = true;
              //this.form.bus_person_abogado_id = null;
              //this.keyReload++;
              //this.$emit('close');
              this.$emit("finished");
            }
          })
          .catch((er) => {
            this.loading = false;
          });
      }
    },
    isLawyer() {
      if (this.user.grupo.identifier == "abogado") {
        return true;
      }
      return false;
    },
    taskDone(event) {
      if (this.event) {
        this.event.tarea_realizada = true;
      } else {
        this.task.tarea_realizada = true;
      }
    },
  },
};
</script>


<template>

<div class="pb-4">
    <v-list  style="width:100%">
      <template v-for="(item, index) in items" >
      <v-divider></v-divider>
      <v-list-item>
       <v-list-item-content>
        <div><div class="text-subtitle-2">Creado  {{$filters.date(item.aud_fecha_creacion)}}, 
          <span v-if="item.bus_person_responsable_id">{{item.bus_person_responsable_id.name}}</span>
        <template v-if="item.tarea_vencimiento"><br> Vence el {{$filters.date(item.tarea_vencimiento)}}</template>
        </div></div>
        <v-list-item-subtitle>
          <v-chip small v-for="tag in item.etiquetas" :key="tag.id"> {{tag.name}} </v-chip>
        </v-list-item-subtitle>
        <div class="pt-2" style="overflow: auto; width: 100%" v-html="item.texto"></div>
       </v-list-item-content>
      </v-list-item>
      </template>
    </v-list>  
  

</div>
</template>

<script>

  export default {
    name: 'historial-seguimiento',
    data(){
      return {
        items: [],
        loading: false
      }
    },
    props: ['solicitud'],
    mounted(){
      this.fetchInfo();
    },
    watch: {
      solicitud(){
        this.fetchInfo();
      }
    },
    methods: {
      fetchInfo(){
        if(!this.solicitud){
          return
        }
        // 'api/admin/seguimiento-credito/list'
        this.$http.post('api/default/bus_seguimiento_credito/list', 
          { data: { bus_solicitud_id: [{ id: this.solicitud }] },
                    _order: [ { field: 'aud_fecha_creacion', direction: 'DESC'}],
                    _paginate: { page: 1, max_rows: 150 } }, this, true, false).then((response) => {
          // console.log(response)
          //this.loading = false;
          //if(response.data.success) {
            this.items = response.items;
            this.renderKey++;
         // }
        });
      }      
    },
  }
</script>

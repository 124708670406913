<template>
  <div>
    <default-app-bar @click="">
      <template v-slot:title>
        <div class="text-h3">Gestión de Cartera</div>
      </template>

      <template v-slot:actions>
        <router-link
          to="/admin/solicitudes/list-desembolsado"
          color="primary"
          class="mr-2"
        >
          Volver
        </router-link>
        <a
          small
          color="primary"
          class="btn-margin0"
          elevation="1"
          v-on:click="print"
        >
          <v-icon small>mdi-file-pdf </v-icon>
        </a>
      </template>
    </default-app-bar>

    <!--default-app-bar > 
         <template v-slot:title>
          <div class="text-h3 mr-2"> Gestión de Cartera  </div>
         </template>
    
         
       
       </default-app-bar-->
    <v-row no-gutters>
      <v-col cols="12" sm="12" class="form-col">
        <v-container fluid class="pl-5 pr-5 pb-1 pt-1">
          <div class="text-h4">Filtros</div>
          <v-row no-gutters v-if="form">
            <v-col cols="12" sm="12" class="form-col3">
              <ng-chips
                v-once
                v-bind:form="form"
                v-bind:options="config.bus_person_cliente_id"
              ></ng-chips>
            </v-col>
            <!--v-col cols="12" sm="6" class="form-col" v-if="user.grupo.identifier != 'cliente-cartera'">
              <ng-range-numeric  v-bind:form="form"  v-bind:options="config.monto"></ng-range-numeric>
            </v-col-->
            <!--v-col cols="12" sm="6" class="form-col">
              <ng-range-numeric  v-bind:form="form"  v-bind:options="config.dias_vencimiento"></ng-range-numeric>
            </v-col-->
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="12">
              <div class="float-right mt-1">
                <v-btn small color="error" v-on:click="clean">
                  <v-icon left>mdi-eraser-variant</v-icon> Limpiar</v-btn
                >
                <v-btn small color="primary" v-on:click="search">
                  <v-icon left>mdi-magnify</v-icon> Buscar</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-3">
      <v-col cols="12" sm="12" class="form-col">
        <v-list>
          <v-divider></v-divider>
          <template v-for="item in items">
            <v-list-item>
              <v-list-item-content class="item-credito">
                <!--v-list-item-title-->
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2px;
                  "
                >
                  <span class="b1"> Crédito {{ item.id }} </span>
                  <span class="b1">{{ item.cliente }}</span>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2px;
                  "
                >
                  <span>
                    Cuotas Generadas: {{ item.numero_cuotas_generadas }}
                  </span>
                  <span>Días vencidos: {{ item.dias_sin_pagar }}</span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2px;
                  "
                >
                  <span> Fecha última acción </span>
                  <span> {{ $filters.date(item.fecha_seguimiento) }}</span>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2px;
                  "
                >
                  <span> Valor vencido </span>
                  <span> {{ $filters.currency(item.valor_vencido) }}</span>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2px;
                  "
                >
                  <span> Valor por vencer </span>
                  <span> {{ $filters.currency(item.valor_xvencer) }}</span>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn dark icon @click="showInfo(item)" color="primary"
                  ><v-icon>mdi-menu-right</v-icon></v-btn
                >
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-list>

        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <historial-credito
            :item="currentItem"
            @finished="reloadList"
            @close="dialog = false"
          ></historial-credito>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { NgForm, handler } from "@/components/gui/NgForm";
import HistorialCredito from "./HistorialCredito";
export default {
  name: "NgGestionCobroClientes",
  components: { HistorialCredito },
  data() {
    return {
      loading: true,
      items: [],
      user: null,
      form: null,
      currentItem: null,
      dialog: false,
      renderKey: 1,
      info: null,
      tmpl: "",
      config: {
        bus_person_cliente_id: {
          label: "Cliente",
          dense: false,
          url: "api/v1/admin/person/search",
          name: "bus_person_cliente_id",
        },
        bus_convenio_admin_cartera_id: {
          label: "Convenio",
          url: "api/admin/person/search-inver",
          name: "bus_convenio_admin_cartera_id",
        },
        monto: { label: "Monto vencido", name: "monto" },
        dias_vencimiento: {
          label: "Días vencimiento",
          name: "dias_vencimiento",
        },
      },
      headers: [
        { text: "Crédito", value: "id" },
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "cliente",
        },
        { text: "Cuotas Generadas", value: "numero_cuotas_generadas" },
        { text: "Valor vencido", value: "valor_vencido" },
        { text: "Valor por vencer", value: "valor_xvencer" },
        { text: "Días vencidos", value: "dias_sin_pagar" },
        { text: "Fecha última acción", value: "fecha_seguimiento" },
        { text: "Etiquetas", value: "etiquetas" },
      ],
    };
  },
  mounted() {
    //console.log(this.pageConfg)
    const form = new NgForm(this.config, this);
    this.form = new Proxy(form, handler);
    this.fetchInfo();
    //this.loadItem();
  },
  methods: {
    showInfo(item) {
      this.currentItem = item;
      this.dialog = true;
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    search() {
      this.fetchInfo();
    },
    reloadList() {
      //console.log('jijijijij')
      this.fetchInfo();
    },
    clean() {},
    fetchInfo() {
      this.$http
        .post(
          "api/admin/resumen-clientes",
          { data: this.form.value },
          this,
          true,
          false
        )
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.success) {
            this.items = response.items;
            this.renderKey++;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    esAbogado() {
      if (this.user.grupo.identifier == "abogado") {
        return true;
      }
      return false;
    },
  },
  created() {
    let u = localStorage.getItem("user");
    this.user = JSON.parse(u);
  },
};
</script>


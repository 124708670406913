<template>
     <div >

      <!--table style="position: relative;width:100%" class="table-bordered" >
           <thead>
           <tr>
             <th style="position: sticky;top: 0;width:60px">Cuota</th>
             <th style="position: sticky;top: 0;width:80px">Fecha de Corte</th>
             <th style="position: sticky;top: 0;width:80px">Fecha limite</th>
             <th style="position: sticky;top: 0;">Días de Mora</th>
             <th style="position: sticky;top: 0;">Total</th>
             <th style="position: sticky;top: 0;">Pagado</th>
             <th style="position: sticky;top: 0;">Saldo</th>
           </tr>
           </thead>
      <tbody-->
      <v-list  style="width:100%">
      <template v-for="item in items" >
        <v-list-item v-bind:class="{ vencida: (item.dias_sin_pagar > 0 && item.total_cuota - item.total_pagado > 0 && item.estado_cuota < 3) }">
          <v-list-item-content>
          <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
              <span class="b1"> Cuota Nro: {{item.cuota}}  </span> 
              <span class="b1"> Fecha de Corte: {{ $filters.date(item.fecha_corte)}}</span>
          </div>
   
          <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
              <span> Fecha limite  </span> 
              <span >{{ $filters.date(item.fecha_limite_pago)}}</span>
          </div>
           <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
              <span > Días de Mora  </span> 
              <span >{{ item.dias_sin_pagar}}</span>
          </div>

          <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
              <span > Total  </span> 
              <span >{{ $filters.currency(item.total_cuota)}}</span>
          </div>

          <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
              <span > Pagado  </span> 
              <span >{{ $filters.currency(item.total_pagado)}}</span>
          </div>

          <div style="display: flex;justify-content: space-between;margin-bottom:2px;">
              <span > Saldo  </span> 
              <span >{{ $filters.currency((item.total_cuota - item.total_pagado))}}</span>
          </div>

        </v-list-item-content>

      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-list>
      

    </div>
</template>

<script>

  export default {
    name: 'CuotasCreditoSimple',
    data(){
        return {
            reloadKey: 1,
            items: null,
            conceptos: {},
            expanded: [],
            singleExpand: true,
            headers: [],
            totales: {}
        }
    },
    props: ['solicitud'],
    mounted(){
       this.loadItem();
    },
    watch: {
      solicitud(){
        this.loadItem();
      }
    },
    methods: {
      loadItem(){
        if(!this.solicitud){
          return
        }
        this.$http.get( 'api/admin/solicitudes/cuotas-credito-simple/'+this.solicitud )
        .then(res => {
            console.log(res);
            if(res.data.success){
              //console.log(res.data)
              for(const c of res.data.conceptos){
                this.conceptos[c.id] = c;
              }
             const headers = [
               { text: '', value: 'data-table-expand', width: '45px' },
                { text: '#', align: 'start', sortable: true, value: 'cuota' },
                { text: 'F. corte',  align: 'start',  sortable: true, value: 'fecha_corte' },
                { text: 'F. limite', align: 'start', sortable: true, value: 'fecha_limite_pago' },
                //{ text: 'Cuota Fija', align: 'start', sortable: true, value: 'cuota_fija'},
                //{ text: 'IxPagar', align: 'start', sortable: true,  value: 'interes_xpagar' },
                { text: 'Días Mora', align: 'start', sortable: true, value: 'dias_sin_pagar' }
                //{ text: 'MxPagar', align: 'start', sortable: true, value: 'mora_xpagar' }
              ]
              const items = res.data.items;
       
              //headers.push({ text: 'Aporte Capital', align: 'start', sortable: true, value: 'aporte_capital' });
              headers.push({ text: 'Total', align: 'start', sortable: true, value: 'total_cuota' });
              headers.push({ text: 'Estado', align: 'start', sortable: true, value: 'estado_cuota' });
              
              
              // items.push(res.data.totales);
              
              this.headers = headers;
              this.items = items;
              this.reloadKey++;
            }
        }).catch(err => {
          console.log(err);
        });
      }
    },   
  }
</script>
